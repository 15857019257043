<div id="individuals-doc">
    <h1>
        <span *ngIf="data?.payload"> {{data.payload.fullname}}</span>
        <span *ngIf="!data?.payload"> {{'IND.FULL_REPO' | translate}}</span>
    </h1>
    <p>{{'IND.ASK_TYPE_DOC' | translate}}</p>

    <div class="options-container">
        <mat-checkbox class="example-margin" [formControl]="FormGroup?.controls.authorities">
            {{'IND.AUTHORITY' | translate}}</mat-checkbox>
        <mat-checkbox class="example-margin" [formControl]="FormGroup?.controls.stock">
            {{'IND.STOCK' | translate}}</mat-checkbox>
        <mat-checkbox class="example-margin" [formControl]="FormGroup?.controls.powers">
            {{'IND.POWER' | translate}}</mat-checkbox>
        <mat-checkbox class="example-margin" [formControl]="FormGroup?.controls.powers_old">
            {{'INDV.POA_NO_VIGENTES' | translate}}</mat-checkbox>
    </div>

    <div class="actions-container">
        <button mat-button class="btn btn-default" mat-dialog-close (click)="close()">{{'BUTTONS.CANCEL' |
            translate}}</button>
        <button mat-button class="btn btn-primary" (click)="getReport()" [mat-dialog-close]="FormGroup?.value"
            [disabled]="(!FormGroup?.value.authorities && !FormGroup?.value.stock && !FormGroup?.value.powers && !FormGroup?.value.powers_old)"
            cdkFocusInitial>{{'BUTTONS.DOWNLOAD' | translate}}</button>
    </div>
</div>