export const CountriesEs = {
  ID11: 'Argentina',
  ID40: 'Chile',
  ID65: 'Estados Unidos de América',
  ID141: 'México',
  ID178: 'Paraguay',
  ID239: 'Uruguay',
  ID1: 'Afganistán',
  ID2: 'Albania',
  ID3: 'Alemania',
  ID4: 'Andorra',
  ID5: 'Angola',
  ID6: 'Anguila',
  ID7: 'Antártida',
  ID8: 'Antigua y Barbuda',
  ID9: 'Arabia Saudita',
  ID10: 'Argelia',
  ID12: 'Armenia',
  ID13: 'Aruba',
  ID14: 'Australia',
  ID15: 'Austria',
  ID16: 'Azerbaiyán',
  ID18: 'Bahamas',
  ID19: 'Bahrein',
  ID20: 'Bangladesh',
  ID21: 'Barbados',
  ID17: 'Bélgica',
  ID22: 'Belice',
  ID23: 'Benín',
  ID24: 'Bhután',
  ID25: 'Bielorrusia',
  ID26: 'Birmania',
  ID27: 'Bolivia',
  ID28: 'Bosnia y Herzegovina',
  ID29: 'Botsuana',
  ID30: 'Brasil',
  ID31: 'Brunéi',
  ID32: 'Bulgaria',
  ID33: 'Burkina Faso',
  ID34: 'Burundi',
  ID35: 'Cabo Verde',
  ID36: 'Camboya',
  ID37: 'Camerún',
  ID38: 'Canadá',
  ID39: 'Chad',
  ID41: 'China',
  ID42: 'Chipre',
  ID43: 'Ciudad del Vaticano',
  ID44: 'Colombia',
  ID45: 'Comoras',
  ID48: 'Corea del Norte',
  ID49: 'Corea del Sur',
  ID50: 'Costa de Marfil',
  ID51: 'Costa Rica',
  ID52: 'Croacia',
  ID53: 'Cuba',
  ID54: 'Curazao',
  ID55: 'Dinamarca',
  ID56: 'Dominica',
  ID57: 'Ecuador',
  ID58: 'Egipto',
  ID59: 'El Salvador',
  ID60: 'Emiratos Árabes Unidos',
  ID61: 'Eritrea',
  ID62: 'Eslovaquia',
  ID63: 'Eslovenia',
  ID64: 'España',
  ID66: 'Estonia',
  ID67: 'Etiopía',
  ID68: 'Filipinas',
  ID69: 'Finlandia',
  ID70: 'Fiyi',
  ID71: 'Francia',
  ID72: 'Gabón',
  ID73: 'Gambia',
  ID74: 'Georgia',
  ID75: 'Ghana',
  ID76: 'Gibraltar',
  ID77: 'Granada',
  ID78: 'Grecia',
  ID79: 'Groenlandia',
  ID80: 'Guadalupe',
  ID81: 'Guam',
  ID82: 'Guatemala',
  ID83: 'Guayana Francesa',
  ID84: 'Guernsey',
  ID85: 'Guinea',
  ID86: 'Guinea Ecuatorial',
  ID87: 'Guinea-Bissau',
  ID88: 'Guyana',
  ID89: 'Haití',
  ID90: 'Honduras',
  ID91: 'Hong kong',
  ID92: 'Hungría',
  ID93: 'India',
  ID94: 'Indonesia',
  ID96: 'Irak',
  ID95: 'Irán',
  ID97: 'Irlanda',
  ID98: 'Isla Bouvet',
  ID99: 'Isla de Man',
  ID100: 'Isla de Navidad',
  ID101: 'Isla Norfolk',
  ID102: 'Islandia',
  ID103: 'Islas Bermudas',
  ID104: 'Islas Caimán',
  ID105: 'Islas Cocos (Keeling)',
  ID106: 'Islas Cook',
  ID107: 'Islas de Åland',
  ID108: 'Islas Feroe',
  ID109: 'Islas Georgias del Sur y Sandwich del Sur',
  ID110: 'Islas Heard y McDonald',
  ID111: 'Islas Maldivas',
  ID112: 'Islas Malvinas',
  ID113: 'Islas Marianas del Norte',
  ID114: 'Islas Marshall',
  ID115: 'Islas Pitcairn',
  ID116: 'Islas Salomón',
  ID117: 'Islas Turcas y Caicos',
  ID118: 'Islas Ultramarinas Menores de Estados Unidos',
  ID119: 'Islas Vírgenes Británicas',
  ID120: 'Islas Vírgenes de los Estados Unidos',
  ID121: 'Israel',
  ID122: 'Italia',
  ID123: 'Jamaica',
  ID124: 'Japón',
  ID125: 'Jersey',
  ID126: 'Jordania',
  ID127: 'Kazajistán',
  ID128: 'Kenia',
  ID129: 'Kirguistán',
  ID130: 'Kiribati',
  ID131: 'Kuwait',
  ID133: 'Laos',
  ID134: 'Lesoto',
  ID135: 'Letonia',
  ID132: 'Líbano',
  ID136: 'Liberia',
  ID137: 'Libia',
  ID138: 'Liechtenstein',
  ID139: 'Lituania',
  ID140: 'Luxemburgo',
  ID143: 'Macao',
  ID144: 'Macedônia',
  ID145: 'Madagascar',
  ID146: 'Malasia',
  ID147: 'Malawi',
  ID148: 'Mali',
  ID149: 'Malta',
  ID150: 'Marruecos',
  ID151: 'Martinica',
  ID152: 'Mauricio',
  ID153: 'Mauritania',
  ID154: 'Mayotte',
  ID155: 'Micronesia',
  ID156: 'Moldavia',
  ID142: 'Mónaco',
  ID157: 'Mongolia',
  ID158: 'Montenegro',
  ID159: 'Montserrat',
  ID160: 'Mozambique',
  ID161: 'Namibia',
  ID162: 'Nauru',
  ID163: 'Nepal',
  ID164: 'Nicaragua',
  ID165: 'Niger',
  ID166: 'Nigeria',
  ID167: 'Niue',
  ID168: 'Noruega',
  ID169: 'Nueva Caledonia',
  ID170: 'Nueva Zelanda',
  ID171: 'Omán',
  ID172: 'Países Bajos',
  ID173: 'Pakistán',
  ID174: 'Palau',
  ID175: 'Palestina',
  ID176: 'Panamá',
  ID177: 'Papúa Nueva Guinea',
  ID179: 'Perú',
  ID180: 'Polinesia Francesa',
  ID181: 'Polonia',
  ID182: 'Portugal',
  ID183: 'Puerto Rico',
  ID184: 'Qatar',
  ID185: 'Reino Unido',
  ID186: 'República Centroafricana',
  ID187: 'República Checa',
  ID189: 'República de Sudán del Sur',
  ID46: 'República del Congo',
  ID47: 'República Democrática del Congo',
  ID188: 'República Dominicana',
  ID190: 'Reunión',
  ID191: 'Ruanda',
  ID192: 'Rumanía',
  ID193: 'Rusia',
  ID194: 'Sahara Occidental',
  ID195: 'Samoa',
  ID196: 'Samoa Americana',
  ID197: 'San Bartolomé',
  ID198: 'San Cristóbal y Nieves',
  ID199: 'San Marino',
  ID200: 'San Martín (Francia)',
  ID201: 'San Pedro y Miquelón',
  ID202: 'San Vicente y las Granadinas',
  ID203: 'Santa Elena',
  ID204: 'Santa Lucía',
  ID205: 'Santo Tomé y Príncipe',
  ID206: 'Senegal',
  ID207: 'Serbia',
  ID208: 'Seychelles',
  ID209: 'Sierra Leona',
  ID210: 'Singapur',
  ID211: 'Sint Maarten',
  ID212: 'Siria',
  ID213: 'Somalia',
  ID214: 'Sri lanka',
  ID215: 'Sudáfrica',
  ID216: 'Sudán',
  ID217: 'Suecia',
  ID218: 'Suiza',
  ID219: 'Surinám',
  ID220: 'Svalbard y Jan Mayen',
  ID221: 'Swazilandia',
  ID223: 'Tailandia',
  ID224: 'Taiwán',
  ID225: 'Tanzania',
  ID222: 'Tayikistán',
  ID226: 'Territorio Británico del Océano Índico',
  ID227: 'Territorios Australes y Antárticas Franceses',
  ID228: 'Timor Oriental',
  ID229: 'Togo',
  ID230: 'Tokelau',
  ID231: 'Tonga',
  ID232: 'Trinidad y Tobago',
  ID233: 'Tunez',
  ID234: 'Turkmenistán',
  ID235: 'Turquía',
  ID236: 'Tuvalu',
  ID237: 'Ucrania',
  ID238: 'Uganda',
  ID240: 'Uzbekistán',
  ID241: 'Vanuatu',
  ID242: 'Venezuela',
  ID243: 'Vietnam',
  ID244: 'Wallis y Futuna',
  ID245: 'Yemen',
  ID246: 'Yibuti',
  ID247: 'Zambia',
  ID248: 'Zimbabue',
};

export const CountriesEn = {
  ID11: 'Argentina',
  ID40: 'Chile',
  ID65: 'United States of America',
  ID141: 'Mexico',
  ID178: 'Paraguay',
  ID239: 'Uruguay',
  ID1: 'Afghanistan',
  ID2: 'Albania',
  ID3: 'Germany',
  ID4: 'Andorra',
  ID5: 'Angola',
  ID6: 'Anguila',
  ID7: 'Antarctica',
  ID8: 'Antigua and Barbuda',
  ID9: 'Saudi Arabia',
  ID10: 'Algeria',
  ID12: 'Armenia',
  ID13: 'Aruba',
  ID14: 'Australia',
  ID15: 'Austria',
  ID16: 'Azerbaijan',
  ID18: 'Bahamas',
  ID19: 'Bahrain',
  ID20: 'Bangladesh',
  ID21: 'Barbados',
  ID17: 'Belgium',
  ID22: 'Belize',
  ID23: 'Benin',
  ID24: 'Bhutan',
  ID25: 'Belarus',
  ID26: 'Burma',
  ID27: 'Bolivia',
  ID28: 'Bosnia and Herzegovina',
  ID29: 'Botswana',
  ID30: 'Brazil',
  ID31: 'Brunei',
  ID32: 'Bulgaria',
  ID33: 'Burkina Faso',
  ID34: 'Burundi',
  ID35: 'Cape Verde',
  ID36: 'Cambodia',
  ID37: 'Cameroon',
  ID38: 'Canada',
  ID39: 'Chad',
  ID41: 'China',
  ID42: 'Cyprus',
  ID43: 'Vatican City',
  ID44: 'Colombia',
  ID45: 'Comoros',
  ID48: 'North Korea',
  ID49: 'South Korea',
  ID50: 'Ivory Coast',
  ID51: 'Costa Rica',
  ID52: 'Croatia',
  ID53: 'Cuba',
  ID54: 'Curacao',
  ID55: 'Denmark',
  ID56: 'Dominica',
  ID57: 'Ecuador',
  ID58: 'Egypt',
  ID59: 'El Salvador',
  ID60: 'United Arab Emirates',
  ID61: 'Eritrea',
  ID62: 'Slovakia',
  ID63: 'Slovenia',
  ID64: 'Spain',
  ID66: 'Estonia',
  ID67: 'Ethiopia',
  ID68: 'Philippines',
  ID69: 'Finland',
  ID70: 'Fiji',
  ID71: 'France',
  ID72: 'Gabon',
  ID73: 'Gambia',
  ID74: 'Georgia',
  ID75: 'Ghana',
  ID76: 'Gibraltar',
  ID77: 'Pomegranate',
  ID78: 'Greece',
  ID79: 'Greenland',
  ID80: 'Guadalupe',
  ID81: 'Guam',
  ID82: 'Guatemala',
  ID83: 'French Guiana',
  ID84: 'Guernsey',
  ID85: 'Guinea',
  ID86: 'Equatorial Guinea',
  ID87: 'Guinea-Bissau',
  ID88: 'Guyana',
  ID89: 'Haiti',
  ID90: 'Honduras',
  ID91: 'Hong Kong',
  ID92: 'Hungary',
  ID93: 'India',
  ID94: 'Indonesia',
  ID96: 'Iraq',
  ID95: 'Iran',
  ID97: 'Ireland',
  ID98: 'Bouvet Island',
  ID99: 'Isle of Man',
  ID100: 'Christmas Island',
  ID101: 'Norfolk Island',
  ID102: 'Iceland',
  ID103: 'Bermuda Islands',
  ID104: 'Cayman Islands',
  ID105: 'Cocos (Keeling) Islands',
  ID106: 'Cook Islands',
  ID107: 'Åland Islands',
  ID108: 'Faroe Islands',
  ID109: 'South Georgia and the South Sandwich Islands',
  ID110: 'Heard and McDonald Islands',
  ID111: 'Maldive Islands',
  ID112: 'Malvinas Islands',
  ID113: 'Northern Mariana Islands',
  ID114: 'Marshall Islands',
  ID115: 'Pitcairn Islands',
  ID116: 'Solomon Islands',
  ID117: 'Turks and Caicos Islands',
  ID118: 'United States Minor Outlying Islands',
  ID119: 'British Virgin Islands',
  ID120: 'United States Virgin Islands',
  ID121: 'Israel',
  ID122: 'Italy',
  ID123: 'Jamaica',
  ID124: 'Japan',
  ID125: 'Jersey',
  ID126: 'Jordan',
  ID127: 'Kazakhstan',
  ID128: 'Kenya',
  ID129: 'Kyrgyzstan',
  ID130: 'Kiribati',
  ID131: 'Kuwait',
  ID133: 'Laos',
  ID134: 'Lesotho',
  ID135: 'Latvia',
  ID132: 'Lebanon',
  ID136: 'Liberia',
  ID137: 'Libya',
  ID138: 'Liechtenstein',
  ID139: 'Lithuania',
  ID140: 'Luxembourg',
  ID143: 'Macao',
  ID144: 'Macedonia',
  ID145: 'Madagascar',
  ID146: 'Malaysia',
  ID147: 'Malawi',
  ID148: 'Mali',
  ID149: 'Malta',
  ID150: 'Morocco',
  ID151: 'Martinique',
  ID152: 'Mauricio',
  ID153: 'Mauritania',
  ID154: 'Mayotte',
  ID155: 'Micronesia',
  ID156: 'Moldova',
  ID142: 'Monaco',
  ID157: 'Mongolia',
  ID158: 'Montenegro',
  ID159: 'Montserrat',
  ID160: 'Mozambique',
  ID161: 'Namibia',
  ID162: 'Nauru',
  ID163: 'Nepal',
  ID164: 'Nicaragua',
  ID165: 'Niger',
  ID166: 'Nigeria',
  ID167: 'Niue',
  ID168: 'Norway',
  ID169: 'New Caledonia',
  ID170: 'New Zealand',
  ID171: 'Oman',
  ID172: 'Netherlands',
  ID173: 'Pakistan',
  ID174: 'Palau',
  ID175: 'Palestine',
  ID176: 'Panama',
  ID177: 'Papua New Guinea',
  ID179: 'Peru',
  ID180: 'French Polynesia',
  ID181: 'Poland',
  ID182: 'Portugal',
  ID183: 'Puerto Rico',
  ID184: 'Qatar',
  ID185: 'United Kingdom',
  ID186: 'Central African Republic',
  ID187: 'Czech Republic',
  ID189: 'Republic of South Sudan',
  ID46: 'Republic of the Congo',
  ID47: 'Democratic Republic of the Congo',
  ID188: 'Dominican Republic',
  ID190: 'Meeting',
  ID191: 'Rwanda',
  ID192: 'Romania',
  ID193: 'Russia',
  ID194: 'Western Sahara',
  ID195: 'Samoa',
  ID196: 'American Samoa',
  ID197: 'Saint Bartholomew',
  ID198: 'Saint Kitts and Nevis',
  ID199: 'San Marino',
  ID200: 'Saint Martin (France)',
  ID201: 'Saint Pierre and Miquelon',
  ID202: 'Saint Vincent and the Grenadines',
  ID203: 'Saint Helena',
  ID204: 'Saint Lucia',
  ID205: 'Sao Tome and Principe',
  ID206: 'Senegal',
  ID207: 'Serbia',
  ID208: 'Seychelles',
  ID209: 'Sierra Leone',
  ID210: 'Singapore',
  ID211: 'Sint Maarten',
  ID212: 'Syria',
  ID213: 'Somalia',
  ID214: 'Sri lanka',
  ID215: 'South Africa',
  ID216: 'Sudan',
  ID217: 'Sweden',
  ID218: 'Switzerland',
  ID219: 'Suriname',
  ID220: 'Svalbard and Jan Mayen',
  ID221: 'Swaziland',
  ID223: 'Thailand',
  ID224: 'Taiwan',
  ID225: 'Tanzania',
  ID222: 'Tajikistan',
  ID226: 'British Indian Ocean Territory',
  ID227: 'French Southern and Antarctic Territories',
  ID228: 'East Timor',
  ID229: 'Togo',
  ID230: 'Tokelau',
  ID231: 'Tonga',
  ID232: 'Trinidad and Tobago',
  ID233: 'Tunisia',
  ID234: 'Turkmenistan',
  ID235: 'Türkiye',
  ID236: 'Tuvalu',
  ID237: 'Ukraine',
  ID238: 'Uganda',
  ID240: 'Uzbekistan',
  ID241: 'Vanuatu',
  ID242: 'Venezuela',
  ID243: 'Vietnam',
  ID244: 'Wallis and Futuna',
  ID245: 'Yemen',
  ID246: 'Djibouti',
  ID247: 'Zambia',
  ID248: 'Zimbabwe',
};

export const ExpirationRangeEn = {
  ID1: 'Expired',
  ID2: 'Less than 30 days',
  ID3: 'Between 30 and 60 days',
  ID4: 'More than 60 days',
  ID5: 'Resolved',
};

export const ExpirationRangeEs = {
  ID1: 'Vencido',
  ID2: 'Menos de 30 Días',
  ID3: 'Entre 30 y 60 Días',
  ID4: 'Más de 60 Días',
  ID5: 'Resuelta',
};

export const ExpirationTypeEn = {
  ID1: 'Authorities',
  ID2: 'POA',
  ID3: 'Company',
  ID4: 'Legal representative',
  ID5: 'Custom',
  ID6: 'Contracts',
  ID7: 'Balances',
};

export const ExpirationTypeEs = {
  ID1: 'Directorio',
  ID2: 'Poderes',
  ID3: 'Sociedad',
  ID4: 'Representante Legal',
  ID5: 'Personalizada',
  ID6: 'Contratos',
  ID7: 'Balances',
};

export const ShareInfoEs = {
  ID1: 'Información General',
  ID2: 'Domicilio',
  ID3: 'Accionistas/Socios y Capital Social/Miembros',
  ID4: 'Autoridades/Sujetos',
  ID5: 'Poderes',
  ID10: 'Bienes Aportados',
  ID11: 'Formation Documents',
  ID12: 'Vencimientos',
  ID6: 'Estatuto/Contrato Constitutivo',
  ID8: 'Contratos',
  ID9: 'Balances',
  ID7: 'Documentos',
};

export const ShareInfoEn = {
  ID1: 'General information',
  ID2: 'Address',
  ID3: 'Shareholders/Partners and capital stock/Members',
  ID4: 'Authorities/Subjects',
  ID5: 'POA',
  ID10: 'Assets provided',
  ID11: 'Formation Documents',
  ID12: 'Expirations',
  ID6: 'Bylaws/Founding contract',
  ID8: 'Contracts',
  ID9: 'Balances',
  ID7: 'Documents',
};

export const RolePersonEs = {
  ID1: 'Director',
  ID3: 'Accionista',
  ID13: 'Representante Legal',
};

export const RolePersonEn = {
  ID1: 'Authority/Director',
  ID3: 'Shareholder',
  ID13: 'Legal Representative',
};

export const POAStausEn = {
    ID1: 'No status',
    ID2: 'Current',
    ID3: 'Expanded',
    ID4: 'Partially Revoked',
    ID5: 'Revoked',
    ID6: 'Ended',
    ID7: 'Expired'
  }

export const POAStausEs = {
  ID1: 'Sin estado',
  ID2: 'Vigente',
  ID3: 'Ampliado',
  ID4: 'Revocado Parcialmente',
  ID5: 'Revocado',
  ID6: 'Agotado',
  ID7: 'Vencido'
}

export const DocumentTypeEs = {
  ID1: 'Cambio de Sede',
  ID2: 'Constitución De Sociedad Anónima',
  ID3: 'Reforma de Estatuto',
  ID4: 'Directorios',
  ID5: 'Poderes',
  ID6: 'Aprobación de EECC',
  ID7: 'Modificación de Capital',
  ID8: 'Constitución de SRL',
  ID9: 'Otros',
  ID10: 'Revocar poderes',
  ID14: 'Primera Sesión de Directorio',
  ID15: 'Aprobación de EECC y Designación de Directorio',
  ID16: 'Registro de accionistas',
  ID17: 'Inscripción de Sociedad Extranjera',
  ID18: 'Constitucion',
  ID19: 'Inscripción Registro de Comercio',
  ID20: 'Publicación diario oficial',
  ID21: 'Formulario inicio actividades',
  ID22: 'E-RUT',
  ID23: 'Compraventa acciones',
  ID24: 'Suscripción y pago de acciones',
  ID26: 'Sesión de Directorio',
  ID27: 'Junta Ordinaria de Accionistas',
  ID28: 'Junta Extraordinaria de Accionistas',
  ID29: 'Modificación social',
  ID30: 'Poderes',
  ID31: 'Certificados',
  ID32: 'Otros',
  ID33: 'Estatutos de la Sociedad',
  ID34: 'Constancia de la Constitución de la Sociedad',
  ID35: 'Poderes',
  ID36: 'Constancia de RUC',
  ID37: 'Patente Municipal',
  ID38: 'Licencia Comercial',
  ID39: 'Memoria del Directorio',
  ID40: 'Informe del Síndico',
  ID41: 'Estados Financieros',
  ID42: 'Acta de Asamblea',
  ID43: 'Acta de Directorio',
  ID44: 'Registro de asistencia',
  ID45: 'Fotocopia del Libro de Registro de Acciones',
  ID46: 'Cambio de Sede',
  ID47: 'Reforma de Estatuto',
  ID48: 'Directorios',
  ID49: 'Aprobación de EECC',
  ID50: 'Modificación de Capital',
  ID51: 'Revocar poderes',
  ID52: 'Bylaws',
  ID53: 'Certificate of formation',
  ID54: 'Others',
  ID55: 'Ubicacion del Fideicomiso',
  ID56: 'Bienes Aportados',
  ID57: 'Acta constitutiva y modificaciones',
  ID58: 'Poder',
  ID59: 'Documento del apoderado',
  ID60: 'Comprobante de domicilio',
  ID61: 'Inscripcion en SAT',
  ID62: 'Opinión de cumplimiento SAT',
  ID63: 'Opinión de cumplimiento IMSS',
  ID64: 'Opinión de cumplimiento INFONAVIT',
  ID65: 'Inscripción en REPSE',
  ID66: 'Contrato',
  ID67: 'Balance',
  ID68: 'Otros',
  ID69: 'Acta constitutiva',
  ID70: 'Asamblea General Ordinaria de Accionistas',
  ID71: 'Asamblea General Extraordinaria de Accionistas',
  ID72: 'Resoluciones Unánimes tomadas Fuera de Asamblea General de Accionistas',
  ID73: 'Asamblea General Ordinaria Anual de Accionistas'
};

export const DocumentTypeEn = {
  ID1: 'Change of headquarters',
  ID2: 'SA constitution',
  ID3: 'Bylaw reform',
  ID4: 'Directories',
  ID5: 'Powers of attorney',
  ID6: 'Approval of financial statements',
  ID7: 'Capital amendment',
  ID8: 'SRL constitution',
  ID9: 'Others',
  ID10: 'Revoke power of attorney',
  ID14: 'First board meeting',
  ID15: 'Approval of financial statements and designation of directories',
  ID16: 'Register of shareholders',
  ID17: 'Registration of a foreign company',
  ID18: 'Constitution',
  ID19: 'Registration in trade registry',
  ID20: 'Publicaiton in the official gazzette',
  ID21: 'Start of activities form',
  ID22: 'E-RUT',
  ID23: 'Trading of shares',
  ID24: 'Subscription and payment of shares',
  ID26: 'Board meeting',
  ID27: 'Ordinary shareholders meeting',
  ID28: 'Extraordinary shareholders meeting',
  ID29: 'Social modification',
  ID30: 'Powers of attorney',
  ID31: 'Certificate',
  ID32: 'Others',
  ID33: 'Company bylaws',
  ID34: 'Certificate of incorporation',
  ID35: 'Powers of attorney',
  ID36: 'RUC certificate',
  ID37: 'Municipal patent',
  ID38: 'Comercial license',
  ID39: 'Board memory',
  ID40: 'Syndic s report',
  ID41: 'Financial statements',
  ID42: 'Assembly minute',
  ID43: 'Board minute',
  ID44: 'Attendance record',
  ID45: 'Photocopy of the Share Registry Book',
  ID46: 'Change of headquarters',
  ID47: 'Bylaw reform',
  ID48: 'Directories',
  ID49: 'Approval of financial statements',
  ID50: 'Capital amendment',
  ID51: 'Revoke powers of attorney',
  ID52: 'Bylaws',
  ID53: 'Certificate of formation',
  ID54: 'Others',
  ID55: 'Location of the trust',
  ID56: 'Assets provided',
  ID57: 'Articles of Incorporation and amendments',
  ID58: 'Powers of attorney',
  ID59: 'Proxy document',
  ID60: 'Address certificate',
  ID61: 'SAT registration',
  ID62: 'Compliance opinion SAT',
  ID63: 'Compliance opinion IMSS',
  ID64: 'Compliance opinion INFONAVIT',
  ID65: 'REPSE registration',
  ID66: 'Contracts',
  ID67: 'Balances',
  ID68: 'Others',
  ID69: 'Constitutive document',
  ID70: 'General meeting of shareholders',
  ID71: 'Extraordinary general meeting of shareholders',
  ID72: 'Unanimous resolutions deliberated outside the general assembly',
  ID73: 'Anual general meeting of shareholders'
};

export const UserTypeEs = {
  IDa: 'Administrador',
  IDf: 'Firmante',
  IDe: 'Editor',
  IDr: 'Solo lectura',
  IDc: 'Cliente',
};

export const UserTypeEn = {
  IDa: 'Administrator',
  IDf: 'Signatory',
  IDe: 'Editor',
  IDr: 'Read only',
  IDc: 'Customer',
};

export const PowerTypeEs = {
  ID1: 'Administración',
  ID2: 'Gestiones administrativas',
  ID3: 'Operaciones bancarias',
  ID4: 'Cobrar y percibir',
  ID5: 'Conferir Actos juridicos',
  ID6: 'Otorgamiento de escrituras',
  ID7: 'Adquisición y enajenación de bienes',
  ID8: 'Locación',
  ID9: 'Prestamo',
  ID10: 'General de Administración y Disposición',
  ID11: 'Actuaciones Judiciales',
  ID12: 'Actos de disposición',
  ID13: 'Otras',
  ID14: 'Judiciales',
  ID15: 'Administrativos y Laborales',
  ID16: 'Bancarios',
  ID17: 'Comerciales',
  ID18: 'Administration',
  ID19: 'Administrative formalities',
  ID20: 'Banking operations',
  ID21: 'Collect and receive',
  ID22: 'Confer legal acts',
  ID23: 'Granting of deeds',
  ID24: 'Acquisition and disposal of assets',
  ID25: 'Rental',
  ID26: 'Loan',
  ID27: 'General of administration and disposal',
  ID28: 'Judicial proceedings',
  ID29: 'Acts of disposition',
  ID30: 'Others',
  ID31: 'Asuntos Laborales',
  ID32: 'Cobranzas',
  ID33: 'Asuntos Fiscales',
  ID34: 'Pleitos y cobranzas',
  ID35: 'Actos de administración',
  ID36: 'Actos de administración en materia laboral',
  ID37: 'Actos de dominio',
  ID38: 'Representación en asambleas',
  ID39: 'Otros',
  ID40: 'Representación en asambleas',
  ID41: "General y amplio de administración y disposición",
  ID42: "Actos de mera administración",
  ID43: "Actos de libre disposición de bienes",
  ID44: "Cobrar y percibir",
  ID45: "Facultades bancarias o financieras",
  ID46: "Préstamos o endeudamiento de la compañía",
  ID47: "Facultades laborales y de seguridad social",
  ID48: "Actuaciones judiciales",
  ID49: "Autocontratación",
  ID50: "Gestiones y trámites administrativos",
  ID51: "Comparecencia ante autoridades",
  ID52: "Facultad de delegar y/o conferir poderes a terceros",
  ID53: "Otras",
  ID67: "Poder General para Celebrar Operaciones Intercompañías",
  ID68: "Poder General Cambiario",
  ID69: "Poder General Cambiario y para Actos de Administración",
  ID70: "Poder General para Actos de Administración ante Autoridades"
};

export const PowerTypeEn = {
  ID1: 'Administration',
  ID2: 'Administrative formalities',
  ID3: 'Banking operations',
  ID4: 'Collect and perceive',
  ID5: 'Confer legal acts',
  ID6: 'Granting of deeds',
  ID7: 'Adquisition and disposal of assets',
  ID8: 'Rental',
  ID9: 'Loan',
  ID10: 'General of administration and disposal',
  ID11: 'Judicial proceedings',
  ID12: 'Acts of disposition',
  ID13: 'Others',
  ID14: 'Judicial',
  ID15: 'Administrative and labor',
  ID16: 'Bankers',
  ID17: 'Commercial',
  ID18: 'Administration',
  ID19: 'Administrative formalities',
  ID20: 'Banking operations',
  ID21: 'Collect and receive',
  ID22: 'Confer legal acts',
  ID23: 'Granting of deeds',
  ID24: 'Acquisition and disposal of assets',
  ID25: 'Rental',
  ID26: 'Loan',
  ID27: 'General of administration and disposal',
  ID28: 'Judicial proceedings',
  ID29: 'Acts of disposition',
  ID30: 'Others',
  ID31: 'Labor matters',
  ID32: 'Collections',
  ID33: 'Fiscal matters',
  ID34: 'Litigation and collections',
  ID35: 'Administrative acts',
  ID36: 'Administrative acts in labor matters',
  ID37: 'Ownership acts',
  ID38: 'Assembly representation',
  ID39: 'Others',
  ID40: 'Assembly representation',
  ID41: "General and broad for administration and disposition",
  ID42: "Acts of mere administration",
  ID43: "Acts of free disposal of assets",
  ID44: "Collect and receive",
  ID45: "Banking or financial powers",
  ID46: "Company loans or indebtedness",
  ID47: "Labor and social security powers",
  ID48: "Judicial proceedings",
  ID49: "Self-contracting",
  ID50: "Administrative procedures and formalities",
  ID51: "Presence before authorities",
  ID52: "Authority to delegate and/or confer powers of attorney to third parties",
  ID53: "Others",
  ID67: "POA for Intercompanies transaction",
  ID68: "POA for FX operations",
  ID69: "FX and Administration Acts POA",
  ID70: "POA for Administrative Acts before Authorities"
};

export const TratamientoES = {
  ID1: "Sin tratamiento",
  ID2: "Sr.",
  ID3: "Sra."
};

export const TratamientEN = {
  ID1: "No title",
  ID2: "Mr.",
  ID3: "Mrs."
};

export const ActionTypeES = {
  ID1: 'Nominativas / No endosables',
  ID2: 'Escriturales'
}

export const ActionTypeEN = {
  ID1: 'Non-endorsable nominative shares',
  ID2: 'Registered shares'
}

export const ActionTypeChileES = {
  ID3: 'Ordinarias',
  ID4: 'Preferentes'
}

export const ActionTypeChileEN = {
  ID3: 'Ordinary shares',
  ID4: 'Preferred shares'
}