import { companyListEN } from 'src/app/modules/company-list/company-list';
import { statisticsEN } from 'src/app/modules/company-list/general-statistics/statitics';
import { docFormationEN } from 'src/app/modules/company-view/doc-formation/doc-formation';
import { expirationStudioEN } from 'src/app/modules/expirations/expiration-list/expiration';
import { IndividualsEN } from 'src/app/modules/individuals/individuals';
import { ShareInfoTableEN } from 'src/app/modules/information-shared/information-shared.model';
import { PowersEN } from 'src/app/modules/powers-upload/powers-upload';
import { ActionTypeChileEN, ActionTypeEN, CountriesEn, DocumentTypeEn, ExpirationRangeEn, ExpirationTypeEn, POAStausEn, PowerTypeEn, RolePersonEn, ShareInfoEn, TratamientEN, UserTypeEn } from 'src/app/modules/shared/combos-lang';
import { userMenuEN } from 'src/app/pages/layout/navbar/models/profile-options';

export const English = {
  lang: 'EN',
  data: {
    INDV: IndividualsEN,
    USER_MENU: userMenuEN,
    COUNTRIES: CountriesEn,
    EXPIRATIONRANGE: ExpirationRangeEn,
    EXPIRATIONTYPE: ExpirationTypeEn,
    POASTATUS: POAStausEn,
    SHAREINFO: ShareInfoEn,
    ROLEPERSON: RolePersonEn,
    DOCUMENTTYPE: DocumentTypeEn,
    USERTYPE: UserTypeEn,
    POWERTYPE: PowerTypeEn,
    POWERS: PowersEN,
    STATS: statisticsEN,
    TRATAMIENTO: TratamientEN,
    ACTIONTYPE: ActionTypeEN,
    ACTIONTYPECHILE: ActionTypeChileEN,
    TITLES: {
      COMPANIES: 'Companies',
      FOLLOWERS: 'Followers',
      ORIGIN_COUNTRY: 'Country of origin',
      SHARE_INFO: 'Share Information',
      LIBRARY: 'Library',
      EXPIRATIONS: 'Expirations',
      USERS: 'Users',
      LOGS: 'Logs list',
      PERSONS: 'Persons',
      PROFILE: 'Profile',
    },
    CAPITAL: {
      SOCIAL: 'Capital stock',
      SUBS: 'Suscribed capital stock',
      NOM_VALUE: 'Nominal value',
      NOM_VALUE_TODAY: 'Current nominal value',
      SHARES: 'Shares',
      SOCIAL_Q: 'Social quotas',
      TOTAL_CAPITAL_STOCK: 'Total capital stock',
      FIXED_CAP: 'Fixed capital stock',
      VARIABLE: 'Variable capital stock',
      INT: 'Integrated capital stock',
      CURRENCY_TYPE: 'Currency type',
      OVERVIEW: "Overview"
    },
    TABS: {
      GENERAL_INFO: "General information",
      EXPIRATIONS: "Expirations",
      POWERS: "POA",
      STATUTE: "Bylaws",
      STATUTES: "Bylaws",
      DOCS: "Formation documents",
      MAP: "Corporate Map",
      BALANCES: "Balances",
      CONTRATOS: "Contracts",
      SHAREHOLDERS: "Shareholders and capital stock",
      PARTNERS: "Partners and capital stock",
      MEMBERS: "Members",
      SUBJECTS: "Subjects",
      AUTHORITIES: "Authorities",
      DIRECTORY_REP: "Directory and Legal Representatives",
      DIRECTORY: "Directory",
      MANAGMENT: "Managment",
      ADMIN_REP: "Administration and Legal Representatives",
      ASSETS_PROVIDED: "Assets provided"
    },
    UBICATION: {
      POSTAL_CODE: 'Postal code',
      JURISDICTION: 'Jurisdiction',
      COUNTRY: 'Country',
      FLOOR: 'Floor',
      OFFICE_NUMBER: 'Office number',
    },
    WIZARD: {
      DURATION_EXCERCISE: 'Duration and excercise',
      DURATION_TERM: 'Term of duration',
      DURATION_TERM_COMPANY: 'Term of duration',
      YEAR_CLOSING: 'Year-end closing',
      ADDRESS: 'Address',
      BYLAW: 'Bylaw',
      BYLAWS: 'Bylaws',
      BYLAW_MX: 'Bylaw',
      CAPITAL_STOCK: 'Capital stock',
      AMOUNT_SHARE: 'Amount of shares',
      TYPE_SHARE: 'Type of shares',
      NOMINAL_VALUE: 'Nominal value',
      OBJECT: 'Corporate purpose',
      MAX_AMOUNT: 'Maximum amount',
      MIN_AMOUNT: 'Minimum amount',
      INDEF_TERM: 'Indefinite term',
      SUB_CAPITAL_STOCK: 'Suscribed capital stock',
      INT_CAPITAL_STOCK: 'Integrated capital stock',
      CAP_STOCK_AND_SHARES: 'Capital stock and shares',
      ADDRESS_AND_HEAD: 'Address and headquarters',
      ADDRESS_2: 'Street',
      DOOR: 'Door number',
      APARTMENT: 'Apartment number',
      HEADQUARTERS: 'Headquarters',
      HEAD_MUST_BE: 'Headquarters must be within the address',
      OFFICE_TERM: 'Term of office',
      DIRECTORY: 'Directory',
      MANAGERS: 'Managers',
      ADMIN: 'Administration',
      NO_INFO: 'No Information',
      AMOUNT_DIRECTORS: 'Amount of Incumbent Directors',
      DIRECTORS_SUP: 'Alternate Directors',
      DIRECTORS_TIT: 'Incumbent Directors',
      MANAGERS_SUP: 'Alternate Managers',
      MANAGERS_TIT: 'Incumbent Managers',
      ADMINS_TIT: "Incumbent Administrators",
      ADMINS_SUP: "Alternate Administrators",
      AUTHORITIES_TIT: "Incumbent Authorities",
      AUTHORITIES_SUP: "Alternate Authorities",
      AMOUNT_DIRECTORS_SUP: 'Amount of Alternate Directors',
      SOCIAL_QUOTAS: 'Social quotas',
      INDET_MANAGERS_TIT: 'Undetermined number of incumbent managers',
      INDET_MANAGERS_SUP: 'Undetermined number of alternate managers',
      PUBLIC_REGISTRY: 'Public registry registration number',
    },
    LABELS: {
      DOC_DETAILS: 'Documents details',
      DOC_SHARE: 'Share document',
      SHARE_TO: 'Shared to',
      ACTIVE: 'Actives',
      INACTIVE: 'Inactives',
      ALL: 'All',
      RENAME: 'Rename',
      AMOUNT_SHARE: "Shares",
      SELECT_FOLDER: 'Select Folder',
      FOLDER_NAME: "Folder name",
      COMPANY_NAME: "Company name",
      COMPANY_TYPE: "Company type",
      CORPORATE_DATA_CONTROL: "Corporate Data Control",
      DOC_TYPE: "Document type",
      DOC_NAME: "Document name",
      DOC_DATE: "Document date",
      DOC_CONTENT: 'Document Content',
      PROCEDURE_TYPE: "Procedure type",
      PROCESS_NUM: "Process number",
      SELECT_DOCS: "Select Document(s)",
      SELECT_DOC: "Select Document",
      SELECT_PROCEDURES: "Seleccione un tipo de trámite",
      STATUS: 'Status',
      VERIFICATION_STATUS: "Verification Status",
      VERIFIED: "Verified",
      VERIFIED_BY: "Verified by",
      NOT_VERIFY: "Not verified",
      VERIFY: "Verified",
      LAST_VERIFICATION: "Last verification",
      LAST_MODIFICATION: "Last modification",
      MODIFIED_BY: "Modified by",
      PREVIEW: "Preview",
      LOADING: "Loading",
      COMPANIES: "Companies",
      COMPANY: "Company",
      CONVERT_TO: "Convert to Company",
      EDIT: "Edit",
      DELETE: "Delete",
      SELECT: "Select",
      USER: "User",
      DOCUMENT_TYPE: "Document Type",
      NAME: "Name",
      LAST_NAME: "Last name",
      PHONE: "Phone number",
      EMAIL: "Email",
      ACTIONS: "Actions",
      FOLDER: "Folder",
      CREATE: "Create",
      EXPIRATION_DATE: "Expiration date",
      APPROVAL_DATE: "Date of approval",
      FILING_DATE: "Filing date",
      EXPIRATION_TYPE: "Expiration type",
      EXPIRATION_STATUS: "Expiration status",
      EXPIRATION: "Expiration",
      AUTHORITY: "Authority",
      DIRECTOR: "Director",
      DIRECTORS: "Directors",
      MANAGER: "Manager",
      MANAGERS: "Managers",
      ADMIN: "Administrator",
      ADMINS: "Administrators",
      PRESIDENT: "President",
      VICEPRESIDENT: "Vice President",
      MANAGER_TIT: "Incumbent Manager",
      MANAGER_SUP: "Alternate Manager",
      ADMIN_TIT: "Incumbent Administrator",
      ADMIN_SUP: "Alternate Administrator",
      DIR_TIT: "Incumbent Director",
      DIR_SUP: "Alternate Director",
      SIND_TIT: "Titular syndic",
      SIND_SUP: "Alternate syndic",
      REP_LEG_TIT: "Incumbent legal representative",
      REP_LEG_SUP: "Alternate legal representative",
      SUBJECT: "Subject",
      SUBJECTS: "Subjects",
      MEMBERS: "Members",
      MEMBER: "Member",
      TRUSTEE: "Trustee",
      FIDUCIARY: "Fiduciary",
      OR_BENEF: "Original Beneficiary",
      OR_TRUST: "Original Trustor",
      ADHE_BENE: "Adherent Beneficiary",
      ADHE_TRUS: "Adherent Trustor",
      GENERAL_MANAGER: "General Manager",
      SPEC_MANAGER: "Special Manager",
      ALT_MANAGER: "Alternate Manager",
      COMMISSARY: "Commissary",
      ADMINISTRATOR: "Administrator",
      INDEPENDENT_COUNSELOR: "Independent Counselor",
      PATRIMONY_COUNSELOR: "Patrimony Counselor",
      COUNSELOR: "Counselor",
      BOARD_SECRETARY: "Board Secretary",
      NON_MEMBER_SECRETARY: "Non member Secretary",
      VOWEL: "Vowel",
      SECRETARY: "Secretary",
      TREASURER: "Treasurer",
      ADDNEW: '--ADD NEW--',
      NEWAUTH: 'Name new authority',
      TITULAR: "Incumbent",
      SUPLENTE: "Alternate",
      SUPLENTES: "Alternates",
      ADVISER: "Adviser",
      DENOMINATION: "Denomination",
      PERSON: "Person",
      INDIVIDUALS: "Individuals",
      LEGAL_ENTITIES: "Legal entities",
      BIRTH: "Date of birth",
      NATIONALITY: "Nacionality",
      OCCUPATION: "Occupation",
      MARITAL_STATUS: "Marital status",
      TITLE: "Title",
      ID_TYPE: "ID type",
      ID_NUMBER: "ID type",
      PERSONAL_INFO: "Personal information",
      ADDITIONAL_INFO: "Additional document",
      ID_COPY: {
        AR: 'Copy of ID card/Passport',
        CL: 'Copy of ID card/Passport',
        PY: 'Copy of ID card/Passport',
        UY: 'Copy of ID card/Passport',
        US: 'Copy of ID card/Passport',
        MX: 'Copy of ID card/Passport',
        DF: 'Copy of ID card/Passport',
      },
      NEW: "new",
      OF: "of",
      SHAREHOLDERS: "Shareholders",
      SHAREHOLDER: "Shareholder",
      PARTNERS: "Partners",
      PARTNER: "Partner",
      LEGAL_REPRESENTATIVE: "Legal representative",
      LEGAL_REPRESENTATIVES: "Legal representatives",
      DOCUMENT: "Document",
      OPERATION_COUNTRIES: "Countries of operation",
      OPERATION_COUNTRY: "Country of operation",
      ATTACH_MAP: "Attach corporate map",
      ATTACH_MAP_CL: "Attach corporate map",
      INFORMATION: "Information",
      NB_PUBLIC_REGISTRY: "Public registry registration number",
      NB_TAX_ID: "Tax identification number",
      NB_TAX_ID_SHORT: "Tax identification number",
      ART_REGISTRATION: "Registration article",
      INFO_REGISTRATION_YEAR: "Registration article",
      CHOOSE_LINK: "Choose the document you want to link",
      UPLOAD_NEW_FILE: "Upload new file",
      UPLOAD_NEW_POA: "Upload new POA",
      DETAIL_POA: "POA Details",
      SHARES: "Shares",
      QUOTAS: "Quotas",
      TOTALS: "Totals",
      GENDER: "The",
      QUESTION_ADD: "The authority you want to add is a",
      QUESTION_ADD_PERSON: '¿The person you want to add is',
      LEGAL_ENTITY: "Legal entity",
      SELECT_NEW: "Select new authority",
      ADD_NEW: "Add new",
      PERCENTAJE: "Percentaje",
      CAPITAL: "Capital",
      FIXED_CAP: "Fixed capital stock",
      VARIABLE_CAP: "Variable capital stock",
      TOTAL_CAPITAL_STOCK: "Total Capital Stock",
      PROFESSIONAL: "Professional in charge",
      CONTROL_BODIES: "Control bodies",
      ACTIVITY: "Activity",
      ACTIVITY_SII: "Activity and SII Code",
      SELECT_ACTIVITY: "Select activities",
      SELECT_CONTROL: "Select control bodies",
      DATE_INCORPORATION: "Date of incorporation",
      PAGES: "Pages",
      NUMBER: "Number",
      YEAR: "Year",
      SELECT_YEAR: "Select year",
      REAL_STATE_CONSERVATIVE: "Real State Conservative",
      PUBLICATION_EXTRACT:
        "Publication of extract of incorporation in the official gazette",
      SUPERVISOR_BODY: "Supervisory body",
      FANTASY_NAME: "Fantasy name",
      COMMERCIAL_NUMBER: "Commercial Registry Registration Number",
      INS_NUM_BANCO_CENTRAL_UY: "Banco Central de Uruguay Registration Number",
      INS_NUM_BANCO_PREV_UY: "Banco de Previsión Social Registration Number",
      DATE_BANCO_CENTRAL_UY: "Banco Central de Uruguay Registration Date",
      BOOK_CUSTODY: "Book custody",
      DENOMINATION_NAME: "Company name",
      PUBLIC_REGISTRY: "Public registry",
      SAT_ACTIVITY: "SAT Activity",
      IMSS: "IMSS Employer Registration",
      OWNERSHIP_PERCENTAJE: "Ownership percentage",
      AMOUNT_SHARES: "Amount of shares",
      ELEVATION_NUMBER: "Script number",
      POA_NAME: "Name of the POA",
      GRANT_DATE: "Grant date",
      PROXIES_NAME: "Name of the proxies",
      POWERS_CONFERRED: "Powers conferred",
      PAID: "Paid",
      FIXED_PORTION: "Fixed portion",
      VARIABLE_PORTION: "Variable portion",
      AMOUNT_SOCIAL: "Amount of social quotas",
      NAME_LASTNAME: "Name and Last name",
      POSITION: "Position",
      TERM_EXPIRATION: "Term expiration",
      DESIGNATION_DATE: 'Desgination date',
      COUNTRY: "Country",
      REPRESENTATIVE: "Representative",
      APPOINTMENT_DATE: "Appointment date",
      POWER: "POA",
      POA_DETAILS: "POA details",
      POA_TYPE: "POA type",
      POA_INFORMATION: "POA Information",
      GENERAL_POA: "General POA",
      TYPE_GENERAL_POA: "General POA type",
      SPECIAL_POA: "Special POA",
      TYPE_SPECIAL_POA: "Special POA type",
      APPOINT_PROXIES: "Appoint Proxies",
      SELECT_PROXIES: "Select Proxy",
      LIST_PROXIES: "List of proxies",
      SELECT_POWER: "Select power",
      LIST_POWERS: "List of powers",
      NOTARY_REG_NUM: "Notary registration number",
      FOLIO_NUMBER: "Sheet",
      FOLIO: "Sheet",
      NOTARY_REG: "Not. Reg.",
      NOTARY_NAME: "Notary name",
      NOTARY_PERSON: "Notary name (person)",
      OBSERVATIONS: "Observations",
      FOLIO_NUM: "Sheet",
      NOTARY_REG_NUMB: "Notary registration number",
      REPERTOIRE_NUMBER: "Repertoire number",
      DEED_NUM: "Deed number",
      DEED_NUMBER: "Deed number",
      DEED_NMB: "Deed Num.",
      DEED_DATE: "Deed date",
      NOTARY_NUMBER: "Notary number",
      POWERS: "Powers",
      LAST_DATE_REGIME: "Last date of compliance with the Information Regime",
      AMOUNT_OF: "Amount of",
      BALANCE: "Balance",
      APPROVAL: "Approval",
      FILING: "Filing",
      CONTRACT: "Contract",
      CONTRACT_TYPE: "Contract type",
      OPEN_FIELD: 'Free text',
      ASSET_NAME: "Name of the asset",
      ASSET: "Asset",
      VIEW_NAME: "View name",
      ROLE: "Role",
      EDIT_INFORMATION: "Edit Information",
      PURPOSE: 'Company purpose',
      REGISTERED_OFFICE: 'Registered office',
      INF_ART_INCORPORATION: 'Information on the articles of incorporation',
      USERNAME: 'Username',
      ENTRY_DATE: 'Entry date',
      USER_TYPE: 'User type',
      INITIALS: 'Initials',
      SHARE_INFORMATION: 'Share Information',
      SIGN: 'Signatory',
      EDITOR: 'Editor',
      CLIENTE: 'Customer',
      READ_ONLY: 'Read Only',
      CLICKORDER: 'Click to order',
      AVAILABLE_CAPITAL: 'Remaining',
      NEW_CLASS: 'New Class',
      UNDEFINED: 'Undefined',
      FOLIO_MER:'Commercial registry file number',
      FIRMA_LIM: 'Signature and Limits',
      SIGNATURE: 'Signature',
      LIMITS: 'Limits',
      CLASS: 'Class'
    },
    OPTIONS: {
      LAW_COL: 'Lawsuits and collections',
      ADMIN_ACTS: 'Acts of Administration',
      ADMIN_ACTS_LAB: 'Acts of administration in labor matters',
      PROP_ACTS: 'Property Acts',
    },
    MESSAGES: {
      PREVIEW_NOT_FOUND: 'Preview not found',
      SUCCESS_MAP: 'Corporate Map was successfully updated',
      ERROR_MAP: 'An error occurred while updating the Corporate map',
      DELETE_QUESTION: 'Are you sure you want to delete',
      UNLINK_QUESTION: 'Are you sure you want to unlink',
      INACTIVE_QUESTION: 'Are you sure you want to deactivate the company? You will be able to activate it again whenever you choose.',
      ACTIVE_QUESTION: 'Are you sure you want to activate the company?',
      DELETE_COMP_QUESTION: 'Are you sure you want to delete the selected company?',
      REMEMBER_DOC:
        'Remember to upload the document that supports this change to the library',
      SUCCESS_UPDATE: 'It has been updated successfully',
      ERROR_UPDATE: 'An error occurred while updating',
      ERROR_INVALID: 'it is an unsupported type of file',
      MUST_SELECT_PROXY: 'You must select at least one Proxy',
      MUST_SELECT_POWER: 'You must select at least one POA',
      EDIT_CONTRACT: 'Edit contract',
      ADD_CONTRACT: 'Add new contract',
      EDIT_ASSET: 'Edit asset',
      ADD_ASSET: 'Add new asset',
      EDIT_BALANCE: 'Edit balance',
      ADD_BALANCE: 'Add new balance',
      DELETE_CONTRACT: 'Are you sure you want to delete this contract?',
      USER_ADMIN: 'Addressed to the account owner. Has access to everything including user administration and payments.',
      USER_SIGN: 'Can edit information and initiate documents and approve them, but cannot add new users.',
      USER_EDIT: 'Can edit information and start documents, but cannot approve them or add new users.',
      USER_READ_ONLY: 'Can only read information.',
      USER_CLIENT: 'View all shared company information and documents.',
      OK_DOC: "Document updated successfully",
      ERR_DOC: "An error occurred while updating document",
      ERR_FOLDER: "You cannot delete folders that contain documents inside"
    },
    BUTTONS: {
      SHARE_FOLDER: 'Share folder',
      SHARE_DOC: 'Share document',
      CLEAN_FILTER: 'Remove Filters',
      OPEN: 'Open',
      FILTER: 'Filter',
      FILTERS: 'Filters',
      UPDATE: 'Update',
      GO_TO_LIBRARY: 'Go to library',
      CORPORATE_SUMMARY: 'Coporate summary',
      VERIFY: 'Verify',
      SHARE: 'Share',
      ATTACH_DOCS: 'Attach documents',
      SEE_ATTACH_DOCS: 'View Linked Documents',
      SAVE: 'Save',
      MODIFY: 'Modify',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      CHANGE_COUNTRY: 'Change Country',
      CLOSE: 'Close',
      NEW: 'New',
      NUEVA: 'New',
      DOC: 'Document',
      DOCS: 'Documents',
      BY_DOC_NAME: 'By document name',
      BY_FOLDER_NAME: 'By folder name',
      BY_CONTENT: 'By document contents',
      UPLOAD: 'Upload',
      DELETE: 'Delete',
      DELETE_SELECTED: 'Delete selected items',
      MOVE_SELECTED: 'Move selected items',
      DOWNLOAD: 'Download',
      MOVE_TO: 'Move to',
      ADD: 'Add',
      ALL: 'All',
      CANCEL: 'Cancel',
      RESOLVED: 'Resolved',
      BACK: 'Back',
      WHY_Q: '¿Why?',
      LINKAGES: 'Linkages',
      FULL_VIEW: 'Full View',
      LOCAL_VIEW: 'Local View',
      SAVE_VIEW: 'Save View',
      SEE: 'See Company',
      LINK_FILE: 'Link File',
      UNLINK: 'Unlink',
      SEE_FILE: 'See File',
      ADD_REP_LEG: 'Add Incumbent legal',
      UPDATE_SHAREHOLDER: 'Update shareholder',
      EDIT: 'Edit',
      EDIT_POWER: 'Edit POA',
      ENTER: 'View POA',
      EDIT_PARTNER: 'Edit Partner',
      LINK_FILES: 'Link files',
      GO_TO_UBICATION: 'Go to file ubication',
      SAVE_CHANGES: 'Save changes'
    },
    PLACEHOLDERS: {
      SEARCH: 'Search',
      USERS: 'Users',
      SEARCH_USER: 'Search user',
      SEARCH_COMPANY: 'Search company',
      SEARCH_BY_NAME: 'Search by Name',
      SEARCH_BY_LAST_NAME: 'Search by lastname'
    },
    STATUTE: {
      DIRECTORY: 'Directory',
      TERM_YEAR: 'Term of existence and fiscal year',
      TERM_COMPANY: 'Term of existence of the company',
      YEAR_CLOSE: 'Year-end closing',
      COMPANY_EXPIRATION_DATE: 'Expiration date of the companys',
      AUTHORITIES: 'Authorities',
      INCUMBENT_DIR: 'Incumbent Directors',
      SUP_DIR: 'Alternate Directors',
      MAX_Q: 'Maximum amount',
      MIN_Q: 'Minimum amount',
      TERM_OFFICE: 'Term of office',
      OBJ: 'Legal purpose',
      OPEN_FIELD: 'Open field',
      NUM_DIC: 'Amount of Directors',
      IND_TERM: 'Indefinite term',
      UNDET_NUM: "Undetermined number of"
    },
    COMPANY_LIST: companyListEN,
    SHARE_INFO_TABLE: ShareInfoTableEN,
    DOC_FORMATION_TABLE: docFormationEN,
    EXP_STUDIO_TABLE: expirationStudioEN,
    IND: {
      NAME_LASTNAME: 'Fullname',
      AUTHORITY: 'Authority',
      STOCK: 'Shareholder',
      POWER: 'Proxies',
      FULL_REPO: 'Full report',
      ASK_TYPE_DOC: '¿Que informacion desea descargar?'
    },
    MENU: {
      LIBRARY: 'Library',
      DOCUMENTS: 'Documents',
      PERSONS: 'Persons',
      COMPANIES: 'Companies',
      EXPIRATIONS: 'Expirations',
      TRAMITES: 'Registration procedures',
      SHAREINFO: 'Share Information',
      ADD: 'Add',
      CORPOTATE_MAP: 'Corporate map',
    },
    PROFILE: {
      LOGOUT: 'Log out',
      USERS_SETTINGS: 'Users settings',
    },
    TABLES: {
      EMPTY: 'No records to display',
    },
    TEXT: {
      YES: 'Yes',
      NO: 'No',
      ADV_SEARCH: 'Advanced Search',
      CHOOSE_COUNTRY_SIMPLE: "Select the company's country",
      CHOOSE_COMPANY: 'Select Company',
      CHOOSE_COUNTRY: "Select the company's country of origin",
      ADD_CUSTOM_EXPIRATION: 'Add custom expiration',
      EDIT_CUSTOM_EXPIRATION: 'Edit custom expiration',
      INVALID_SELECTION: 'Invalid selection',
      SURE_TO_DELETE: ' Are you sure you want to remove the expiration?',
      SELECT_MODULE_ERROR: 'Select at least one section',
      POLITICALLY_EXPOSED: 'Politically exposed person',
      CHOOSE_DOC_TYPE: 'Select document type',
      CHOOSE_FOLDER_NAME: 'Select a folder',
      MOVE_FILE: 'Move File',
      MOVE_FILES: 'Move Files',
      NEW_FOLDER: 'New Forder',
      MOVE: 'Move',
      MOVE_HERE: 'Move Here',
      MOVING_FILES: 'Moving files',
      OF: 'of',
      RENAME_FOLDER: 'Rename Folder',
      NEW_FOLDER_NAME: 'New Folder Name',
      CHOOSE_FAC: "Select Facultaty",
      CHOOSE_IND: 'Select proxies',
      POWERS: 'Powers',
      IND: 'Proxies',
    },
    VALIDATION_MESSAGES: {
      MAX_PERM: "Max allowed is ",
      MAX_2: "Max length is 2",
      MAX_20: "Maximum number of characters is 20",
      MAX_30: "Max length is 30",
      MAX_40: "Maximum number of characters is 40",
      MAX_100: "Maximum number of characters is 100",
      MAX_150: "Maximum number of characters is 150",
      MAX_500: "Maximum number of characters is 500",
      MAX_10000: "Maximum number of characters is 10000",

      MAX_DIG_2: "Maximum allowed is 2 digits",
      MAX_DIG_30: "Maximum allowed is 30 digits",
      MAX_DIG_100: "Maximum allowed is 100 digits",

      MIN_DIG_0: "The minimum number is 0",
      MIN_NUM_1: "Minimum number is 1",
      MIN_0_0000000001: "Minimum number allowed is 0.0000000001",

      MAX_NUM_100: "Maximum number is 100",
      MAX_NUM_999: "Maximum number is 999",
      MAX_NUM_1000: "Maximum number is 1000",

      WHITE_SPACE: "You can't enter just white spaces",
      REQUIRED_FIELD: "Required field",
      SEL_FIR: "Select a signature",
      SEL_CAR: "Select a position",

      SOLO_NUM_GUION: "You can only enter numbers and hyphens",
      NUM_INV: "Invalid number",

      FEC_OTOR: "Grant date cannot be after expiration",
      FEC_EXP: "Expiration date cannot be before granting"
    },
    POA: {
      INDISTINCT: "Indistinct",
      INDIVIDUAL: "Individual",
      JOINT: "Joint"
    },
    // SETTING BY COUNTRY CODE
    BY_COUNTRY: {
      AR: {
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Document number',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          DEPARTMENT: 'Department details',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Head office',
          FOREING: 'Local address',
          PROVINCE: 'Province',
          LOCATION: 'Locality',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          FOREING_ADDRESS: 'Foreing Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
        },
      },
      DF: {
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'Document number',
          LEGAL_DOCUMENT_TYPE: 'CUIT',
          STREET: 'Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          DEPARTMENT: 'Department details',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Head office',
          FOREING: 'Local address',
          FOREING_ADDRESS: 'Principal place of business',
          PROVINCE: 'Province',
          LOCATION: 'Locality',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
        },
      },
      CL: {
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          DEPARTMENT: 'Department details',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Head office',
          FOREING: 'Local address',
          PROVINCE: 'Province',
          LOCATION: 'Locality',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          FOREING_ADDRESS: 'Foreing address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
        },
      },
      PY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUC',
          LEGAL_DOCUMENT_TYPE: 'RUC',
          STREET: 'Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          DEPARTMENT: 'Department details',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Head office',
          FOREING: 'Local address',
          PROVINCE: 'Province',
          LOCATION: 'Locality',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          FOREING_ADDRESS: 'Foreing address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
        },
      },
      US: {
        INFO: {
          DOCUMENT_TYPE: 'TAX number',
          FILLING_NUMBER: 'Filling Number',
          REGISTERED_AGENT: 'Registered Agent',
        },
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'ID Card',
          LEGAL_DOCUMENT_TYPE: 'ID Card',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Principal place of business',
          FOREING_ADDRESS: 'Principal place of business',
          PROVINCE: 'Province',
          LOCATION: 'Location',
          FOREING: 'Local address',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
        },
      },
      UY: {
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          DEPARTMENT: 'Department details',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Head office',
          FOREING: 'Local address',
          PROVINCE: 'Province',
          LOCATION: 'Locality',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          FOREING_ADDRESS: 'Foreing address',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
          DOOR: 'Door number',
          APARTMENT: 'Apartment number',
        },
      },
      MX: {
        AUTHORITY: {
          REPRESENTATIVE: 'Appoint proxies',
        },
        INDIVIDUALS: {
          DOCUMENT_TYPE: 'RUT',
          LEGAL_DOCUMENT_TYPE: 'RUT',
          STREET: 'Address',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          DEPARTMENT: 'Department details',
        },
        UBICATION: {
          POSTAL_CODE: 'Postal code',
          ADDRESS: 'Address',
          TYPE: 'Head office',
          FOREING: 'Local address',
          PROVINCE: 'Province',
          LOCATION: 'Locality',
          FIDEICOMISO_DOM: 'Address',
          FIDEICOMISO: 'Place of Trust',
          TAX_ADDRESS: 'Tax address',
          FOREING_ADDRESS: 'Foreing address',
          STREET: 'Street',
          FLOOR: 'Floor',
          ADDRESS_NUMBER: 'Number',
          OFFICE_NUMBER: 'Office number',
        },
      },
    },
    LOGS: {
      DATE_FROM: "Date from",
      DATE_TO: "Date to",
      USERS: "Users",
      DATE: "Date",
      EMAIL: "User",
      DETAIL: "Details",
      DETAIL2: "Details"
    },
    MARITAL_STATUS: {
      CASADO: "Married",
      SOLTERO: "Single",
      DIVORCIADO: "Divorced",
      VIUDO: "Widow",
      UNION: "Coexistence Union"
    }
  }
};
