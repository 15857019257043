<mat-tab-group class="tabs-container">
    <mat-tab *ngFor="let tab of tabsLinkages" label="{{tab.label| translate}}">
        <div class="linkages-container custom-scrollbar">
            <mat-button-toggle-group *ngIf="tab.id === 'powers_companies'" name="viewList"
                (change)="toggleView($event.value)" value="new">
                <mat-button-toggle value="new">{{'INDV.VIGENTES' | translate}}
                </mat-button-toggle>
                <mat-button-toggle value="old">{{'INDV.NO_VIGENTES' | translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>

            <div *ngIf="tab.links?.length === 0">
                <br>
                {{'INDV.NO_LINKS' | translate}}
            </div>
            <div *ngFor="let item of tab.links">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.company_name}} ({{item.total}})
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [ngSwitch]="tab.id">
                        <app-table *ngSwitchCase="'powers_companies'" [dataSource]="item.powers" [columns]="powerTable"
                            (actionClicked)="handleClick($event , 'power' )"></app-table>

                        <app-table *ngSwitchCase="'powers_old_companies'" [dataSource]="item.powers"
                            [columns]="powerTable" (actionClicked)="handleClick($event , 'power' )"></app-table>

                        <app-table *ngSwitchCase="'shaerholder_linkages'" [dataSource]="item.stocks"
                            [columns]="shareholderTable"
                            (actionClicked)="handleClick($event , 'shareholder' )"></app-table>

                        <app-table *ngSwitchCase="'auth_linkages'" [dataSource]="item.authorities" [columns]="authTable"
                            (actionClicked)="handleClick($event , 'directory' )"></app-table>
                    </div>
                </mat-expansion-panel>
                <br>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>